






















































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { BButton, BSpinner } from 'bootstrap-vue'
import ServiceConfirmAlert from '@/components/ServiceConfirmAlert.vue'
import { RouteNames } from '@/router'
import { terminalService } from '@/store/terminalService'
import { SystemState } from '@/graphql/terminal/graphql.schema'
import { Scalars, InventoryActionTypes } from '@/graphql/default/graphql.schema'
import { cart } from '@/store/cart/cartModule'

const BasketProduct = () => import('@/components/BasketProduct.vue')

// Время ожидания для авто выхода
const EXIT_TIMER_LENGTH = 60000 * 5

@Component({
  components: { BasketProduct, BButton, BSpinner, ServiceConfirmAlert }
})
export default class Basket extends Vue {
  readonly RouteNames = RouteNames
  readonly InventoryActionTypes = InventoryActionTypes
  systemState: SystemState[] = []
  serviceAction: InventoryActionTypes | null = null
  timer: ReturnType<typeof setTimeout> | null = null

  @Watch('products')
  resetTimer() {
    if (this.timer) {
      clearTimeout(this.timer)
      this.setExitTimer()
    }
  }

  get products() {
    return cart.products
  }

  async changeQuality(quality:number, productId:Scalars['ID']):Promise<void> {
    cart.changeQuality({ productId, quality })
  }

  created() {
    this.fetch()
  }

  async fetch() {
    const systemState = await terminalService.getSystemState()
    if (Array.isArray(systemState) && systemState.length > 0) {
      systemState.forEach((v) => {
        if (v !== null) this.systemState.push(v)
      })
    }
  }

  confirmService(confirm: boolean) {
    if (confirm && this.serviceAction) {
      this.$router.push({
        name: RouteNames.inventory,
        query: {
          action: this.serviceAction
        }
      })
    }
  }

  showServiceAlert(action: InventoryActionTypes) {
    this.serviceAction = action
    this.$bvModal.show('service-confirm-alert')
  }

  exitServiceMode() {
    this.$router.push({
      name: RouteNames.index
    })
  }

  setExitTimer() {
    this.timer = setTimeout(() => {
      this.exitServiceMode()
    }, EXIT_TIMER_LENGTH)
  }

  mounted():void {
    window.scrollTo({ top: 0 })
    this.setExitTimer()
  }
}
