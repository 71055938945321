














































import { Component, Prop, Vue } from 'vue-property-decorator'
import { BButton, BModal } from 'bootstrap-vue'
import { InventoryActionTypes } from '@/graphql/default/graphql.schema'

@Component({
  components: {
    BButton,
    BModal
  }
})
export default class ServiceConfirmAlert extends Vue {
  @Prop({ type: String, default: 'service-confirm-alert' })
  id!:string

  @Prop()
  action!:InventoryActionTypes

  get actionText() {
    if (this.action === InventoryActionTypes.ReturnToStock) {
      return 'снять на склад'
    } else if (this.action === InventoryActionTypes.ExpiredInTerminal) {
      return 'отправить просрочку'
    } else if (this.action === InventoryActionTypes.Load) {
      return 'загрузить товары'
    }
  }

  onButtonClick(result: boolean) {
    this.$emit('button-clicked', result)
    this.$bvModal.hide(this.id)
  }
}
